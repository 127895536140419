<template>
  <Banner/>
  <Intro/>
  <Features/>
  <HeroStrip/>
  <BlogIntro/>
  <Footer/>
</template>

<script>
export default {
  name: 'HelloWorld',
  components:
  {
    'Banner':require('@/components/Banner.vue').default,
    'Intro':require('@/components/Intro.vue').default,
    'Features':require('@/components/Features.vue').default,
    'HeroStrip':require('@/components/HeroStrip.vue').default,
    'Footer':require('@/components/Footer.vue').default,
    'BlogIntro':require('@/components/BlogIntro.vue').default
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
