<template>
    <section class="navigation">
        <header>
            <div class="header-content">
                <div class="logo"><a href="#"><img src="img/sedna-logo.png" alt="Sedna logo"></a></div>
                <div class="header-nav">
                    <nav>
                        <ul class="primary-nav">
                            <li><a href="#features">Features</a></li>
                            <li><a href="#assets">Assets</a></li>
                            <li><a href="#blog">Blog</a></li>
                            <li><a href="#download">Download</a></li>
                        </ul>
                        <ul class="member-actions">
                            <li><a href="#download" class="login">Log in</a></li>
                            <li><a href="#download" class="btn-white btn-small">Sign up</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="navicon">
                    <a class="nav-toggle" href="#"><span></span></a>
                </div>
            </div>
        </header>
    </section>
</template>
<script>
export default
{
    name:'Navbar-comp'
}
</script>