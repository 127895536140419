<template>
     <section class="hero">
        <Nabvar/>
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <div class="hero-content text-center">
                        <h1>Design, collaborate, win!</h1>
                        <p class="intro">Introducing “Sedna”. A responsive one page website, designed & developed exclusively for Codrops.</p>
                        <a href="#" class="btn btn-fill btn-large btn-margin-right">Download</a> <a href="#" class="btn btn-accent btn-large">Learn more</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="down-arrow floating-arrow"><a href="#"><i class="fa fa-angle-down"></i></a></div>
    </section>
</template>
<script>
export default
{
    name:'banner-comp',
    components:
    {
        'Nabvar': require('@/components/subcomponents/Nabvar.vue').default
    }
}
</script>